import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as api from "@/api/api";
import {Button,Form,Input,Message,Modal,Table,Checkbox,ConfigProvider,Tag,InputNumber,Cascader,Image,Spin } from 'ant-design-vue'
import {PlusOutlined,EnvironmentOutlined} from "@ant-design/icons-vue"

const app=createApp(App)
app.config.globalProperties.$api=api

app.use(Button)
.use(Form)
.use(Input)
.use(Message)
.use(Modal)
.use(Table)
.use(Checkbox)
.use(ConfigProvider)
.use(Tag)
.use(InputNumber)
.use(Cascader)
.use(Image)
.use(Spin)

app.component("PlusOutlined",PlusOutlined)
app.component('EnvironmentOutlined',EnvironmentOutlined)
app.provide("Message",Message)
app.provide("Modal",Modal)
app.use(store).use(router).mount('#app')
