import { createRouter, createWebHashHistory } from 'vue-router'
import {Message} from 'ant-design-vue'


const routes = [
  {
    path: '/',
    redirect:"Index"
  },
  {
    path: '/login',
    name:"Login",
    component:()=>import("../views/Login"),
  },
  {
    path: '/register',
    component:()=>import("../views/Register"),
  },
  {
    path:"/:pathMatch(.*)*",
    name:"404",
    component:()=>import("../views/404.vue")
  },
  {
    path:"/index",
    name:"Index",
    meta:{
    },
    component:()=>import("../views/Index"),
    children:[
      {
        path:"",
        name:"Home",
        component:()=>import("../views/HomeView.vue")
      },
      {
        path:"myOrder",
        name:"MyOrder",
        meta:{
          auth:true
        },
        component:()=>import("../views/MyOrder.vue")
      },
      {
        path: "orderPayment",
        name: "OrderPayment",
        meta: {
          auth: true
        },
        component: () => import("../views/OrderPayment.vue")
      },
      {
        path:"addressList",
        name:"AddressList",
        meta:{
          auth:true
        },
        component:()=>import("../views/AddressList.vue")
      },
      {
        path:"overbooking",
        name:"Overbooking",
        meta:{
          auth:true
        },
        component:()=>import("../views/Overbooking.vue")
      },
      {
        path:"productDetail",
        name:"ProductDetail",
        meta:{
          auth:true
        },
        component:()=>import("../views/ProductDetail.vue")
      },
      {
        path:"directPile",
        name:"DirectPile",
        component:()=>import("../views/DirectPile.vue")
      },
      {
        path:"communicationPile",
        name:"CommunicationPile",
        component:()=>import("../views/CommunicationPile.vue")
      },
      {
        path:"my",
        name:"My",
        meta:{
          auth:true
        },
        component:()=>import("../views/My.vue")
      },
      {
        path:"vipRecharge",
        name:"VipRecharge",
        component:()=>import("../views/VipRecharge.vue")
      },
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeResolve((to,from)=>{
  const userId=localStorage.getItem("userId")
  if(!userId && to.name!="Home" &&   to.meta.auth) {
    console.log("to",to)
    if (to.meta.auth){
      Message.warning("请先登录!")
    }
    return {name:"Home"}
  }
})
router.onError(e=>{
  console.log(e)
})
export default router
