export const getCurrent= (time='',fmt="YYYY-MM-DD")=>{
    let date=time ? new Date(time) :new Date();
    if(/(Y+)/.test(fmt)){
            fmt=fmt.replace(RegExp.$1,(date.getFullYear()+"").substr(4-RegExp.$1.length))
    }
    let o={
            "M+":date.getMonth()+1,
            "D+":date.getDate(),
            "h+":date.getHours(),
            "m+":date.getMinutes(),
            "s+":date.getSeconds()
    }
    for(let k in o){
         if(new RegExp(`(${k})`).test(fmt)){
                 let str=o[k]+"";
                    fmt=fmt.replace(RegExp.$1,(RegExp.$1.length===1) ? str : str.padStart(2,0))
         }
    }
        return fmt
}
// 恢复state对象初始值
export const initStateData=(state)=>{
        for(let key in state) {
          for(let key in state) {
            switch (typeof state[key]) {
              case 'number':
                state[key]=0
                break;
              case 'string':
                state[key]=''
                break;
              case 'undefined':
                state[key]=''
                break;
              case 'function':
                state[key]=()=>{}
                break;
              case 'object':
                  if(state[key] instanceof Array) {
                    state[key]=[]
                  }
                  if(state[key] instanceof Object) {
                    state[key]={}
                  }else{
                    state[key]=null
                  }
                break;
              default:
                state[key]=''
                break;
            }
          }
        }
}
//是不是null类型
export const isNull=(value)=>{
  return (typeof value=='object' && !(value instanceof Object))
}